<template>
    <div id="app">
        <Lookup />
    </div>
</template>

<script>
import Lookup from '@/components/Mapping/LookupByIDList';

export default {
    components: {
        Lookup,
    }
}
</script>